<template>

<div class="container">

  <div class="row">

    <div class="col-12">

      <h3 class="mb-3 mt-3 text-uppercase" v-if="tasks.open && tasks.open.length">Hochzuladende allgemeine und besondere Zulassungs&shy;voraussetzungen</h3>

      <p class="warning">Die aktuelle Dokumenten&shy;vorlage (siehe Verlinkung in den Aufgaben) ist zwingend zu verwenden!</p>

      <div class="task pt-3 pb-3" v-for="task in tasks.open" v-bind:key="task.id">
        <div class="row">

          <div class="col-2 col-lg-1 justify-content-center align-self-center">

            <font-awesome-icon :icon="['far', 'list-check']" size="2x" />

          </div>

          <div class="col-6 col-lg-7">

            <p class="pb-0 mb-0 mt-2">{{ task.name }}</p>
            <!--<p class="text-muted">{{ task.description }}</p>-->

          </div>

          <div class="col-4 col-lg-4 justify-content-center align-self-center text-end">

            <router-link v-if="$store.state.currentApplicationClosedState === false" :to="{ name: 'TaskDetails', params: {id:task.id}}" class="btn btn-primary" ><font-awesome-icon :icon="['far', 'eye']" /><span class="d-none d-lg-inline-block ms-2">Öffnen</span></router-link>

          </div>

        </div>
      </div>

      <h3 class="mb-4 text-uppercase mt-5 text-done" v-if="tasks.completed && tasks.completed.length">Bereits hochgeladene allgemeine und besondere Zulassungs&shy;voraussetzungen</h3>

      <div class="task pt-3 pb-3" v-for="task in tasks.completed" v-bind:key="task.id">
        <div class="row">

          <div class="col-2 col-lg-1 justify-content-center align-self-center task-done-icon">

            <font-awesome-icon :icon="['far', 'list-check']" size="2x" style="color: #7777;" />

          </div>

          <div class="col-6 col-lg-7">
            <p class="pb-0 mb-0 mt-2 text-done">{{ task.name }}</p>
            <!--<p class="text-muted">{{ task.description }}</p>-->

          </div>
          <div class="col-4 col-lg-4 justify-content-center align-self-center text-end">

            <router-link v-if="$store.state.currentApplicationClosedState === false"  :to="{ name: 'TaskDetails', params: {id:task.id}}" class="btn btn-primary" ><font-awesome-icon :icon="['far', 'eye']" /><span class="d-none d-lg-inline-block ms-2">Öffnen</span></router-link>

          </div>

        </div>
      </div>
      <h3 class="mb-4 text-uppercase mt-5 text-done" v-if="tasks.denied && tasks.denied.length">Abgelehnte allgemeine und besondere Zulassungs&shy;voraussetzungen</h3>

      <div class="task pt-3 pb-3" v-for="task in tasks.denied" v-bind:key="task.id">
        <div class="row">

          <div class="col-2 col-lg-1 justify-content-center align-self-center task-done-icon">

            <span v-if="($store.state.currentApplication.request_state === 'readable' || $store.state.currentApplication.name === 'Torwart Leistungskurs' ) && task.comment" class="commentTask delEntry btn btn-small btn-success" @click="showComment(task.status, task.comment)">?</span>
            <font-awesome-icon v-else :icon="['far', 'list-check']" size="2x" style="color: #7777;" />

          </div>

          <div class="col-6 col-lg-7">

            <p class="pb-0 mb-0 mt-2 text-done">{{ task.name }}</p>
            <!--<p class="text-muted">{{ task.description }}</p>-->

          </div>
          <div class="col-4 col-lg-4 justify-content-center align-self-center text-end">

            <router-link v-if="$store.state.currentApplicationClosedState === false"  :to="{ name: 'TaskDetails', params: {id:task.id}}" class="btn btn-primary" ><font-awesome-icon :icon="['far', 'eye']" /><span class="d-none d-lg-inline-block ms-2">Öffnen</span></router-link>

          </div>

        </div>
      </div>
<h3 class="mb-4 text-uppercase mt-5 text-done" v-if="tasks.closed && tasks.closed.length">Akzeptierte allgemeine und besondere Zulassungs&shy;voraussetzungen</h3>

      <div class="task pt-3 pb-3" v-for="task in tasks.closed" v-bind:key="task.id">
        <div class="row">

          <div class="col-2 col-lg-1 justify-content-center align-self-center task-done-icon">


            <span v-if="$store.state.currentApplication.request_state === 'readable' && task.comment" class="commentTask delEntry btn btn-small btn-success" @click="showComment(task.status, task.comment)">?</span>
            <font-awesome-icon v-else :icon="['far', 'list-check']" size="2x" style="color: #7777;" />

          </div>

          <div class="col-6 col-lg-7">
            <p class="pb-0 mb-0 mt-2 text-done">{{ task.name }}</p>
            <!--<p class="text-muted">{{ task.description }}</p>-->

          </div>
          <div class="col-4 col-lg-4 justify-content-center align-self-center text-end">

            <router-link v-if="$store.state.currentApplicationClosedState === false"  :to="{ name: 'TaskDetails', params: {id:task.id}}" class="btn btn-primary" ><font-awesome-icon :icon="['far', 'eye']" /><span class="d-none d-lg-inline-block ms-2">Öffnen</span></router-link>

          </div>

        </div>
      </div>

    </div>

  </div>




</div>
</template>

<script>

import axios from "axios";
import {mapGetters} from "vuex";

export default {

  name: 'TasksComponent',
  data() {
    return {
      tasks: [],
    }
  },
  computed: {
    ...mapGetters(["getUser", "getApiUrl", "getApiAuth"])
  },
  methods:{
    showComment(status, comment) {
      status = this.$t('task.statusTitle.'+status);
      this.$alert(comment, status, 'info',{
        confirmButtonText: 'OK',
        dangerouslyUseHTMLString: true,
      });
    },
    getTasks() {
      const headers = {"crmAuth":localStorage.getItem("dfbToken")};
      let Vueinner = this;
      let external_association = 'dfbRequest_'+this.$store.state.currentApplication.id;
      axios.get(this.getApiUrl + 'customer/' +  this.getUser.crm_customer_id + "/sortedTasks?filter_association="+external_association, {headers}).then((response) => {

        let shortReport = response.data.result;
        Vueinner.tasks = shortReport.data;


      }).catch((e) => {

      });
    }
  },
  created() {
    this.getTasks()
  }
}
</script>

<style lang="scss">

.task {

  border-bottom: 2px solid #f2f2f2;

  &:last-of-type {
    //border-bottom: none;
  }

}
.commentTask {
  border-radius: 100% !important;
  width: 36px;
}

.text-done {

  color: #7777;

}
p.warning {
  background: #ff0000;
  color: #fff;
  font-weight: bold;
  padding: 12px;
  font-size: 18px;
  text-align: center;
}
</style>
