import { createRouter, createWebHistory } from 'vue-router'
import ProfileView from '../views/ProfileView.vue'
import Voraussetzungen from '../views/Voraussetzungen.vue'
import ApplicationsView from '../views/ApplicationsView.vue'
import RegisterView from '../views/RegisterView'
import LoginView from '../views/LoginView'
import LoginOldView from '../views/LoginOldView'
import Auth from '../auth/Auth'
import DashboardView from '../views/DashboardView'
import SonderantragView from '../views/SonderantragView'
import TrainerlizenzView from '../views/TrainerlizenzView'
import store from '../store/index'
import { useToast } from "vue-toastification";
import ApplicationDetails from '../views/ApplicationDetails'
import TaskDetails from '../views/TaskDetails'
import Imprint from '../views/Imprint'
import Issues from '../views/Issues'
const toast = useToast();
const routes = [
  {
    path: '/',
    name: 'home',
    component: RegisterView
  },
  {
    path: '/',
    name: 'register',
    component: RegisterView
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView
  },
  {
    path: '/applications',
    name: 'applications',
    component: ApplicationsView
  },

  {
    path: '/login',
    name: 'login',
    component: LoginView
  },{
    path: '/loginOld',
    name: 'loginOld',
    component: LoginOldView
  },
  {
    path: '/auth/siginwin/main',
    name: 'auth',
    component: Auth,
  },
  {
    path: '/activated',
    name: 'activated',
    component: LoginView
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView
  },
  {
    path: '/sonderantrag',
    name: 'Sonderantrag',
    component: SonderantragView

  },
  {
    path: '/trainerlizenz',
    name: 'Trainerlizenz',
    component: TrainerlizenzView

  },
  {
    path: '/applicationdetails',
    name: 'ApplicationDetails',
    component: ApplicationDetails
  },{
    path: '/imprint',
    name: 'imprint',
    component: Imprint
  },
  {
    path: '/tasks/:id',
    name: 'TaskDetails',
    component: TaskDetails,
  },
  {
    path: '/voraussetzungen',
    name: 'voraussetzungen',
    component: Voraussetzungen,
    children:[
      {
        path: '/voraussetzungen/:id',
        name: 'voraussetzungenDetails',
        component: Voraussetzungen,

      }
      ]
  },
  {
    path: '/issues',
    name: 'Issues',
    component: Issues,
    children:[
      {
        path: '/issues/:id',
        name: 'IssueDetails',
        component: Issues,

      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  store.commit("forceSWupdate");

  if (from.name === 'ApplicationDetails' && to.name !== 'TaskDetails') {

    if (localStorage.getItem("deletedApplication") !== null && localStorage.getItem("deletedApplication") === "true") {
      toast.success('Bewerbung erfolgreich gelöscht!');
      next();
    }
    else {
    if (confirm("Wollen Sie die Bewerbung wirklich verlassen?")) {

      next();
    } else {
      next(false);
    }
    }
  }
  if (to.name === 'activated') {

      store.commit("removeActivation")
      toast('Vielen Dank für die Aktivierung!');

  }
  console.log("toName", to.name);
  if (to.name === 'IssueDetails') {
    localStorage.setItem("return2Url", btoa(to.fullPath));
    console.log("checking login");
    console.log(store.state.loggedInState);
    if (store.state.loggedInState === false) {

      router.push("/login");
    }
  }
  if (store.state.loggedInState === false && to.name !== 'login' && to.name !== 'auth' && to.name !== 'loginOld' && to.name !== 'register' && to.name !== 'voraussetzungen' && to.name !== 'voraussetzungenDetails' && to.name !== 'home' && to.name !== 'activated') {
    router.push({name: 'login'});
  }
  // if ((to.name === 'login' || to.name === 'home' || to.name === 'register' || to.name === "activated") && store.state.loggedInState) {
  //   router.push({name: 'dashboard'});
  // }

  next();
});
export default router
