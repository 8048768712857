<template>
  <div class="upload" :style="'margin-bottom: 48px;'">
    <ul>
      <li v-for="file in files" :key="file.id">
        <span>{{ file.name }}</span> -
        <span>{{ file.size }}</span> -
        <span v-if="file.error">{{ file.error }}</span>
        <span v-else-if="file.success">Upload beendet!</span>
        <span v-else-if="file.active">Upload läuft...</span>
        <span v-else-if="!!file.error">{{ file.error }}</span>
        <span v-else></span>
      </li>
    </ul>

    <file-upload
      :style="'padding:6px !important;'"
      class="btn btn-warning mb-2"
      :class="'me-3'"
      :post-action="postAction"
      extensions="gif,jpg,jpeg,png,webp,pdf"
      accept="image/png,image/gif,image/jpeg,image/webp,application/pdf"
      :multiple="false"
      :size="1024 * 1024 * 10"
      v-model="files"
      @input-filter="inputFilter"
      @input-file="inputFile"
      :headers="headers"
      ref="uploadFront"
    >
      Datei auswählen
    </file-upload>


  </div>
</template>

<script>
import { ref } from 'vue';
import FileUpload from 'vue-upload-component';

export default {
  name: 'UploadComponent',
  components: {
    FileUpload,
  },
  props: {
    specialOrderApplication: null,
    taskId: '',
    entryId: '',
  },

  setup(props, context) {
    const files = ref([]);  // Reactive files array
    const upload = ref(null);
    const postAction = ref('');
    function inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent();
        }

        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent();
        }
      }
    }

    function inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        const fileBlob = newFile.file;

        if (fileBlob) {
          const reader = new FileReader();
          reader.onload = (e) => {
            newFile.base64 = e.target.result;

            context.emit('update:modelValue', newFile.base64);
          };
          reader.readAsDataURL(fileBlob);
        } else {
          console.error('The file could not be read as a Blob.');
        }

        const triggerReloadTask = new CustomEvent('fileAddedInput');
        window.dispatchEvent(triggerReloadTask);
      }

      if (newFile && oldFile) {
        if (newFile.active === false) {
          const triggerReloadTask = new CustomEvent('triggerReloadTask');
          window.dispatchEvent(triggerReloadTask);
        }
      }

      if (!newFile && oldFile) {
        const triggerReloadTask = new CustomEvent('fileRemovedInput');
        window.dispatchEvent(triggerReloadTask);

        context.emit('update:modelValue', null);
      }
    }

    return {
      files,
      upload, postAction,
      inputFilter,
      inputFile,
    };
  },
  created() {
    this.headers = { crmAuth: localStorage.getItem('dfbToken') };

    window.addEventListener('fileAddedInput', this.checkUpload);
    window.addEventListener('fileRemovedInput', this.denyUpload);
  },
};
</script>

<style>
label[for='file'] {
  cursor: pointer;
}
</style>

