<template>
<!--  <MainHeader headline='Trainerlizenz' text='Erfassen Sie Ihre Trainerlizenz'/>-->

  <div class="card p-1 p-lg-4">
    <div class="card-body">


  <template v-if="attestList && attestList.length > 0">

    <div style="margin-bottom: 49px;"  >

      <div class="card p-1 p-lg-4" >
        <div class="card-body">
          <h4 class="pb-3 text-center">{{ attestList[0]._attestTitle }}</h4>
          <p class="card-title text-center">Ihr ärztliches Attest
            <template v-if="attestList[0].verified ==='verified'|| attestList[0].verified === 'verified_complete'"> wurde akzeptiert. </template>
            <template v-else-if="attestList[0].verified === 'waiting' || attestList[0].verified === 'verified_waiting'"> wurde eingereicht und wird geprüft.</template>
            <template v-else-if="attestList[0].verified === 'denied'"> wurde abgelehnt.</template>
          </p>
          <p class="card-text text-center" v-if="attestList[0].verified ==='verified'|| attestList[0].verified === 'verified_complete'">Ihr ärztliches Attest ist gültig bis <strong>{{$filters.formatDate(attestList[0].valid_to)}}.</strong></p>
          <p class="card-text commentText" v-if="attestList[0].verified === 'denied' && attestList[0].verified_comment && attestList[0].verified_comment !== ''" style="text-align: center;     font-style: italic;
    margin-top: 24px;" >
            {{attestList[0].verified_comment}}
          </p>
          <p v-if="attestList[0].verified ==='verified'|| attestList[0].verified === 'verified_complete'" style="text-align: center;" class="fa-4x"><i class="fa-regular fa-circle-check" style="color: green;"></i></p>
          <p v-if="attestList[0].verified ==='waiting'|| attestList[0].verified === 'verified_waiting'" style="text-align: center;" class="fa-4x"><i class="fa-regular fa-circle-question" style="color: #e4c82c;"></i></p>

          <p v-if="attestList[0].verified ==='denied'"  style="text-align: center;" class="fa-4x"  ><i class="fa-regular fa-circle-exclamation" style="color: darkred;"></i></p>
        </div>
      </div>

    </div>

  </template>

      <h4 style="margin-bottom: 24px;">Ärztliches Attest</h4>
      <p>Bitte laden Sie hier <strong>Ihr original Ärztliches Zeugnis über die sportliche Tauglichkeit hoch (bei Abschluss der aktuell laufenden Bewerbungsphase nicht älter als 12 Monate).</strong></p>

<p><strong>Vor dem Hochladen muss zwingend das Ausstellungsdatum des ärztlichen Attests im dafür vorgesehenen Feld eingetragen werden.</strong></p>


      <div class="form-check">
        <input type="checkbox" class="form-check-input" id="truth" v-model="truth">
        <label class="form-check-label" for="truth" style="padding-left: 12px;">Hiermit bestätige ich die Richtigkeit und Echtheit des hochgeladenen Dokuments und die dort hinterlegten Angaben. Mir ist bewusst, dass bei Falschangaben ein Lizenzentziehungsverfahren nach § 32 der DFB-Ausbildungsordnung eingeleitet werden kann.</label>
      </div>
      <div class="form-check">
        <input type="checkbox" class="form-check-input" id="privacy" v-model="privacy">
        <label class="form-check-label" for="privacy" style="padding-left: 12px;" >Ihr ärztliches Attest wird ausschließlich zum Zwecke der einmaligen Kontrolle kurzzeitig verarbeitet und nach Sichtung durch den DFB systemseitig unwiderruflich gelöscht. Weitere Informationen zur Datenverarbeitung und Ihren Betroffenenrechten finden Sie in unserer <a href="https://www.dfb.de/datenschutzerklaerung" target="_blank">Datenschutzerklärung.</a></label>
      </div>
      <p class="card-text" style="margin-bottom: 24px;"> </p>

<div class="row">
  <div class="col-12 col-lg-6">


    <div class="form-floating  mb-3">


    <label for="sellic">Ärztliches Attest wählen</label>
  </div>
    <div class="form-floating mb-3">
      <input class="form-control" id="nextLic" :min="minDateTwelveMonths" :max="maxDateToday" type="date" v-model="attest.valid_from" placeholder="Ausstellungsdatum ärztliches Attest">
      <label for="nextLic">Ausstellungsdatum ärztliches Attest</label>
    </div>


 </div>
  <div class="col-12 col-lg-6">
    <h5 style="margin-top: 18px;"><template v-if="attest.attest_pdf"><i class="fa-regular fa-check-circle" style="color: green;"></i>&nbsp;</template>Attest Datei</h5>
    <template v-if="privacy && truth && isValidDate">
    <UploadComponentAttest :compact="true" v-model="attest.attest_pdf"  v-if="attest.attest_pdf === ''" />
    </template>
    <template v-else>
      <p v-if="attest.valid_from !== ''">Bitte korrektes Datum eintragen und alle Pflichtfelder bestätigen!</p>
    </template>

    <!-- show only if all values are filled -->
    <template v-if="attest.attest_pdf !== ''  && attest.valid_from !== '' && privacy && truth ">
      <template v-if="loading">
        <button type="button" class="btn btn-primary" style="margin-top: 39px;" disabled><i class="fa-regular fa-spinner fa-spin"></i> Speichern</button>
      </template>
      <template v-else>


      <button type="button" class="btn btn-primary" style="margin-top: 39px;" @click="submitToServer()">Speichern</button>
      </template>
    </template>
    <template v-else>

      <button type="button" class="btn btn-primary"  style="margin-top: 155px" disabled >Speichern nach Upload möglich</button>
    </template>
  </div>
</div>

    </div>
  </div>
</template>
<script>
import MainHeader from "@/components/MainHeader";
import UploadComponentAttest from "@/components/UploadComponentAttest.vue";
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: 'AttestView',
  components: {

    MainHeader, UploadComponentAttest
  },
  computed: {
    ...mapGetters(["getApiUrl"]),
    isValidDate() {
      const validFrom = new Date(this.attest.valid_from);
      const minDate = new Date(this.minDateTwelveMonths);
      const maxDate = new Date(this.maxDateToday);
      return validFrom >= minDate && validFrom <= maxDate;
    }
  },
  data() {
    return {
      attests: [],
      attestList: [],
      privacy: false,
      truth: false,

      maxDateToday: new Date().toISOString().substr(0, 10),
      minDateTwelveMonths: new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString().split('T')[0],
      attest: {
        attest_type: 'doctor',
        valid_from: '',
        valid_to: '',
        attest_pdf: ''
      },
      loading: false
    }
  },
  methods: {
    submitToServer() {
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      axios.post(this.getApiUrl + 'trainer/attest'  , this.attest, {
        headers
      })
        .then(response => {
          this.getAttest();
          const triggerReloadTask = new CustomEvent('triggerReloadTask');
          window.dispatchEvent(triggerReloadTask);
          this.attest = {
            attest_type: 'doctor',
            valid_from: '',
            valid_to: '',
            attest_pdf: '',
            verified: ''
          };

        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    getAttest(){
      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      axios.get(this.getApiUrl + 'trainer/attest/list/doctor'  , {
        headers
      })
        .then(response => {
          this.attestList = response.data.data;
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
        });
    },

  },
  created() {

    this.getAttest();
  }
}
</script>
