<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="float-end">
        <template
          v-if="c.c_title && c.gender && c.c_firstname && c.c_lastname && c.c_street && c.c_zipcode && c.c_city && c.c_email && c.c_birthday && c.c_handy && c.birthday_city && c.custom_fields.mannschaft && c.custom_fields.altersbereich && c.custom_fields.liga && c.custom_fields.weiterbildungen">
          <button @click="updateCustomer();" type="button" class="btn btn-secondary mb-3 float-end"><i
            class="fa-regular fa-floppy-disk"></i> Speichern
          </button>
        </template>
        <template v-else>
          <button @click="missingToast" type="button" class="btn btn-secondary mb-3 float-end"><i
            class="fa-regular fa-floppy-disk"></i> Speichern
          </button>
        </template>
      </div>
      <h3 class="pb-3">Profildaten</h3>
      <p style="background: #eee; padding:5px;">Alle Daten sind Pflichtfelder</p>
    </div>
  </div>
  <div class="row">
    <div class=" col-lg-2 ">
      <div class="form-group  form-floating mb-3">
        <select v-model="c.c_title" class="form-select form-floating mb-3">
          <option selected value="">Anrede wählen</option>
          <option value="Herr">Herr</option>
          <option value="Frau">Frau</option>
        </select>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="form-floating mb-3">
        <input class="form-control" id="firstname" type="text" v-model="c.c_firstname" placeholder="Vorname">
        <label for="firstname">Vorname</label>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="form-floating mb-3">
        <input class="form-control" id="lastname" v-model="c.c_lastname" type="text" placeholder="Nachname">
        <label for="lastname">Nachname</label>
      </div>
    </div>
    <div class=" col-lg-2 ">
      <div class="form-group  form-floating mb-3">
        <select v-model="c.gender" class="form-select form-floating mb-3">
          <option selected value="">Geschlecht wählen</option>
          <option value="Männlich">Männlich</option>
          <option value="Weiblich">Weiblich</option>
          <option value="Divers">Divers</option>
          <option value="Keine Angabe">Keine Angabe</option>
        </select>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-4">
      <div class="form-floating mb-3">
        <input class="form-control" id="address" v-model="c.c_street" type="text" placeholder="Anschrift">
        <label for="address">Anschrift</label>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="form-floating mb-3">
        <input class="form-control" id="zipcode" v-model="c.c_zipcode" type="text" placeholder="PLZ">
        <label for="zipcode">PLZ</label>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="form-floating mb-3">
        <input class="form-control" id="city" v-model="c.c_city" type="text" placeholder="Ort">
        <label for="city">Ort</label>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6">
      <div class="form-floating mb-3">
        <input class="form-control" id="mobile" type="text" v-model="c.c_handy" placeholder="Handynummer">
        <label for="mobile">Handynummer</label>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="form-floating mb-3">
        <input class="form-control" id="birthday" type="date" v-model="c.c_birthday" placeholder="YYYY-mm-dd">
        <label for="birthday">Geburtsdatum</label>
      </div>
      <div class="form-floating mb-3">
        <input class="form-control" id="birthday" type="text" v-model="c.birthday_city" placeholder="Geburtsort">
        <label for="birthday">Geburtsort</label>
      </div>
    </div>
    <div class="col-lg-4">
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-lg-6">
      <h3 class="pb-3 mt-3">Aktuelle Trainerstation zum Bewerbungszeitpunkt</h3>
      <div class="form-floating mb-3">
        <input class="form-control" id="birthday" type="text" v-model="c.custom_fields.mannschaft"
               placeholder="Mannschaft">
        <label for="birthday">Mannschaft</label>
      </div>
      <div class="form-floating mb-3">
        <input class="form-control" id="birthday" type="text" v-model="c.custom_fields.liga" placeholder="Liga">
        <label for="birthday">Liga</label>
      </div>
      <div class="form-floating mb-3">
        <input class="form-control" id="birthday" type="text" v-model="c.custom_fields.altersbereich"
               placeholder="Altersbereich">
        <label for="birthday">Altersbereich</label>
      </div>
      <div class="form-check mb-3">
        <input class="form-check-input" type="checkbox" v-model="c.custom_fields.aufstieg" id="defaultCheck1">
        <label class="form-check-label" for="defaultCheck1" style="padding-top:4px; padding-left:4px;">
          Ich bin Aufstiegstrainer*in für die Saison 24/25
        </label>
      </div>
      <div class="form-floating mb-3" v-if="c.custom_fields && c.custom_fields.aufstieg">
        <input class="form-control" type="text" v-model="c.custom_fields.aufstiegVonLiga" placeholder="Von Liga">
        <label>Aufstieg von Liga</label>
      </div>
      <div class="form-floating mb-3" v-if="c.custom_fields && c.custom_fields.aufstieg">
        <input class="form-control" type="text" v-model="c.custom_fields.aufstiegInLiga" placeholder="In Liga">
        <label>Aufstieg in Liga</label>
      </div>
      <div class="form-floating mb-3" v-if="c.custom_fields && c.custom_fields.aufstieg">
        <input class="form-control" type="text" v-model="c.custom_fields.aufstiegVerein" placeholder="mit Verein">
        <label>Aufstieg mit Verein</label>
      </div>
      <div class="form-floating mb-3" v-if="c.custom_fields && c.custom_fields.aufstieg">
        <input class="form-control" type="text" v-model="c.custom_fields.aufstiegMannschaftsart"
               placeholder="Mannschaftsbezeichnung">
        <label>Mannschaftsbezeichnung</label>
      </div>
      <div class="form-floating mb-3" v-if="c.custom_fields && c.custom_fields.aufstieg">
        <input class="form-control" id="aufstiegWann" type="date" v-model="c.custom_fields.aufstiegWann"
               placeholder="Datum Aufstieg">
        <label for="aufstiegWann">Datum Aufstieg</label>
      </div>
      <h3 class="pb-3 mt-3">Sprachkenntnisse</h3>
      <div class="nextLang row mb-4 mb-sm-1" v-for="(lang,ek) in c.custom_fields.language.other">
        <div class="col-12 col-sm-8 col-lg-6">
          <div class="form-floating mb-3">
            <input class="form-control" :id="'nextLang'+ek" type="text"
                   v-model="c.custom_fields.language.other[ek].language" placeholder="Weitere Sprache">
            <label :for="'nextLang'+ek">Weitere Sprache</label>
          </div>
        </div>
        <div class="col-12 col-sm-4 col-lg-6">
          <div class="form-floating mb-3">
            <!-- Possible levels A1, A2, B1, B2, C1, C2 -->
            <select v-model="c.custom_fields.language.other[ek].level" class="form-select form-floating mb-3">
              <option selected value="">Sprachlevel wählen</option>
              <option value="A1">A1</option>
              <option value="A2">A2</option>
              <option value="B1">B1</option>
              <option value="B2">B2</option>
              <option value="C1">C1</option>
              <option value="C2">C2</option>
              <option value="Muttersprache">Muttersprache</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <button class="btn btn-outline-primary"
                  @click="c.custom_fields.language.other.push({language: '', level: ''})"><i
            class="fa-regular fa-plus"></i> Weitere Sprache anlegen
          </button>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <h3 class="pb-3 mt-3 text-center">Profilbild</h3>
      <UploadProfileImage :profileImage="c.profile_image"/>
    </div>
  </div>
</template>
<script>
import BootstrapToast from "@/components/BootstrapToast";
import axios from 'axios'
import {mapGetters, mapActions} from "vuex";
import {useToast} from "vue-toastification";
import UploadProfileImage from "@/components/UploadProfileImage.vue";

export default {
  name: 'ProfileTab',
  components: {
    BootstrapToast, UploadProfileImage
  },
  data() {
    return {
      c: {
        c_title: '',
        gender: '',
        custom_fields: {
          mannschaft: '',
          liga: '',
          altersbereich: '',
          aufstieg: false,
          letzteWeiterbildung: '',
          weiterbildungen: [{date: '', level: ''}],
          language: {
            native: '',
            other: [{language: '', level: ''}]
          }
        }
      },
      currents: [],
      editable: true,
    }
  },
  computed: {
    ...mapGetters(["getUser", "getApiUrl", "getApiAuth"])
  },
  created() {
    window.addEventListener('triggerReloadProfile', this.getCustomerSummaryProfileImage);
    this.getCustomerSummary();
    const headers = {"crmAuth": localStorage.getItem("dfbToken")};
    let VueInner = this;
    axios.get(this.getApiUrl + 'plugins/dfb/currentApplicationOrOverview', {headers}).then((response) => {
      let shortReport = response.data.result;
      if (shortReport.success === true) {
        this.currents = shortReport;
        this.editable = true;
        if (this.currents.currentApplication && (this.currents.currentApplication.length > 1 || (this.currents.currentApplication.length === 1 && this.currents.currentApplication[0].state !== "active"))) {
          this.editable = false;
        }
        if (!this.c || !this.c.c_firstname || !this.c.c_lastname || !this.c.c_street || !this.c.c_birthday || !this.c.c_zipcode || !this.c.c_city || !this.c.c_handy || !this.c.c_email || !this.c.birthday_city || !this.c.custom_fields || !this.c.custom_fields.liga || !this.c.custom_fields.altersbereich || !this.c.custom_fields.mannschaft || !this.c.custom_fields.letzteWeiterbildung || !this.c.profile_image || !this.c.custom_fields.language || !this.c.custom_fields.language.other || !this.c.custom_fields.language.other.length) {
          this.editable = true;
        }
      }
    });
  },
  methods: {
    getCustomerSummaryProfileImage() {
      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      let VueInner = this;
      axios.get(this.getApiUrl + "customer/" + this.getUser.crm_customer_id + "/summary", {headers})
        .then(function (r) {
          let result = r.data.result.data;
          VueInner.c.profile_image = result.data.profile_image;
          VueInner.c.profile_mimetype = result.data.profile_mimetype;
        });
    },
    getCustomerSummary() {
      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      let VueInner = this;
      axios.get(this.getApiUrl + "customer/" + this.getUser.crm_customer_id + "/summary", {headers})
        .then(function (r) {
          let result = r.data.result.data;
          VueInner.c = result.data;
          if (!VueInner.c.custom_fields) {
            VueInner.c.custom_fields = {
              mannschaft: '',
              liga: '',
              altersbereich: '',
              aufstieg: false,
              language: {
                native: '',
                other: [{language: '', level: ''}]
              },
              weiterbildungen: [{date: '', level: ''}]
            };
          }
          if (!VueInner.c.custom_fields.language) {
            VueInner.c.custom_fields.language = {
              native: '',
              other: [{language: '', level: ''}]
            };
          }
          if (!VueInner.c.custom_fields.weiterbildungen) {
            VueInner.c.custom_fields.weiterbildungen = [{date: '', level: ''}];
          }
        });
    },
    updateCustomer() {
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      axios.put(this.getApiUrl + "customer/" + this.getUser.crm_customer_id, VueInner.c, {headers}).then(() => {
        this.$store.dispatch('setToastsDone');
        this.$router.push({name: "applications"});
      }).catch((error) => {
        this.$store.dispatch('handleApiError', error);
      });
    },
    missingToast() {
      const toast = useToast();
      toast.error('Bitte füllen Sie alle Felder aus!')
    }
  }
}
</script>
