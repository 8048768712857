<template>
  <div class="row">
    <div class="col-12">
      <div class="container">
        <h4>Übersicht</h4>
        <p style="margin-bottom: 48px;">Bitte prüfen Sie alle Daten final, bevor Sie die Bewerbung
          abschließen.<br><strong>Beachten Sie, dass die Bewerbung nicht mehrfach eingereicht und nach finaler Absendung
            nicht mehr bearbeitet werden kann.</strong></p>
      </div>
      <Profile class="mt-4" v-if="activeCustomer && options && options.ausbildung && options.ausbildung.infos"
               :customer="activeCustomer" :counters="activeAnswers" :options="options"/>
      <template v-if="$store.state.currentApplicationClosedState !== false">
        <div class="mt-4 openTasks">
          <div class="bg-warning p-4">
            <template
              v-if="$store.state.currentApplication.state === 'declined' || $store.state.currentApplication.state === 'declined_user' ">
              Die Bewerbung wurde nicht übermittelt.
            </template>
            <template v-else>
              Die Bewerbung wurde bereits übermittelt.
            </template>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="mt-4 openTasks"
             v-if="(tasks.open && tasks.open.length) || (nachweise && nachweise.length > 0) || !profileComplete">
          <div class="bg-warning p-4">
            <h3>Voll&shy;ständigkeits&shy;prüfung:</h3>
            <p>Bevor Sie die Bewerbung einreichen können, benötigen wir noch folgende Dokumente von Ihnen:</p>
            <ul>
              <li v-if="tasks.open && tasks.open.length">{{ tasks.open.length }} <span style="cursor: pointer; text-decoration: underline;"
                                                                                       @click="openNavigationTab('task')">allgemeine und besondere Zulassungs&shy;voraus&shy;setzungen</span></li>
              <li v-if="nachweise && nachweise.length > 0">
                <template v-if="nachweise.length === 1">
                  Nachweis über Ihre Erfahrungen
                </template>
                <template v-else>
                  {{ nachweise.length }} Nachweise über Ihre Erfahrungen
                </template>
                <ul>
                  <li v-for="nachweis in nachweise"><span style="cursor: pointer; text-decoration: underline;"
                                                          @click="openNavigationTab(nachweis.question_type)">{{ options[nachweis.question_type].infos.title }}</span>:
                    {{ nachweis.question }}
                  </li>
                </ul>
              </li>
              <li v-if="activeCustomer && !activeCustomer.profile_image">Bitte ergänzen Sie ein Profil&shy;bild.</li>
              <li v-if="!profileComplete">Bitte vervoll&shy;ständigen Sie Ihr <router-link to="profile">Profil</router-link>!</li>
            </ul>
          </div>
        </div>
        <div v-else>
          <div class="closeEvent float-end w-100" style="margin-top:48px; padding:48px; border-top:10px solid #ffc107">
            <p style="text-align: right"><strong>Bewerbung absenden!<br>Wenn Sie sicher sind, können Sie die Bewerbung
              jetzt abschließen.</strong></p>
            <button @click="closeApplication()" class="btn btn-warning float-end">Bewerbung unwiderruflich
              abschließen!
            </button>
          </div>
        </div>
      </template>
    </div>
  </div>
  <Modal v-if="genderModal" @click="genderModal = false;">
    <div class="container">
      <div class="card p-4">
        <p style="text-align: center">Für mehr Informationen zum Stipendium für Trainerinnen, klicken Sie auf das
          Bild.</p>
        <a style="text-align: center" target="_blank"
           href="https://www.bdfl.de/ueber-uns/stipendium-fuer-trainerinnen.html"><img
          style="max-height: 80vh; width:fit-content; margin:0 auto;max-width: 100%;"
          src="@/assets/stipendium.jpeg"></a>
      </div>
    </div>
  </Modal>
</template>
<script>
import axios from "axios";
import Profile from './../Profile';
import {ref} from 'vue';
import {mapGetters} from 'vuex';
import {useToast} from 'vue-toastification'

export default {
  name: 'ApplicationOverview',
  setup() {
    const isShow = ref(false)

    function showModal() {
      isShow.value = true
    }

    function closeModal() {
      isShow.value = false
    }

    return {
      isShow,
      showModal,
      closeModal
    }
  },
  components: {
    Profile
  },
  computed: {
    ...mapGetters(["getUser", "getApiUrl"])
  },
  data() {
    return {
      genderModal: false,
      nachweise: [],
      options: {},
      activeCustomer: null,
      activeAnswers: null,
      tasks: [],
      profileComplete: false,
      allDone: false,
    }
  },
  methods: {
    openPopupGender() {
      if (this.activeCustomer.gender === "Weiblich") {
        if (this.$store.state.currentApplication.name !== 'Torwart Leistungskurs') {
          // Stipendium Popup vorrübergehend deaktiviert
          this.genderModal = true;
        }
      }
    },
    openNavigationTab(question_type) {
      switch (question_type) {
        case 'task':
          question_type = 2;
          break;
        case 'vereinstaetigkeit':
        case 'verbandstaetigkeit':
        case 'internationaltrainer':
          question_type = 3;
          break;
        case 'nationalspieler':
        case 'vereinsspieler':
        case 'internationalspieler':
          question_type = 4;
          break;
        case 'ausbildung':
        case 'trainerausbildung':
        case 'weiterbildung':
          question_type = 5;
          break;

        default:
          question_type = 6;
          break;

      }
      this.$store.state.currentProgress = question_type;
    },
    closeApplication() {
      this.$confirm("Bitte beachten Sie, dass die allgemeinen und besonderen Zulassungsvoraussetzungen sowie das Aufnahmeprüfverfahren dafür im Bewerbungsportal vollständig erfüllt werden müssen.", "Sie möchten die Bewerbung unwiderruflich absenden?", "question", {
        cancelButtonText: "Nein, doch nicht!",
        confirmButtonColor: "#21AE80"
      }).then(() => {
        const toast = useToast();
        const headers = {"crmAuth": localStorage.getItem("dfbToken")};
        axios.put(this.getApiUrl + 'plugins/dfb/closeApplication', {}, {headers}).then((response) => {
          this.$router.push({name: 'applications'})
          toast.success("Vielen Dank für die Übermittlung!");
        }).catch((e) => {
          this.$log.debug(e);
        });
      });
    },
    getTasks() {
      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      let Vueinner = this;
      let external_association = 'dfbRequest_' + this.$store.state.currentApplication.id;
      axios.get(this.getApiUrl + 'customer/' + this.getUser.crm_customer_id + "/sortedTasks?filter_association=" + external_association, {headers}).then((response) => {
        this.$log.debug(response);
        let shortReport = response.data.result;
        Vueinner.tasks = shortReport.data;
        this.$log.debug("tasks", Vueinner.tasks);
      }).catch((e) => {
        this.$log.debug(e);
      });
    },
    getCustomer() {
      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      axios.get(this.getApiUrl + 'customer/' + this.getUser.crm_customer_id, {headers})
        .then(response => {
          let shortResponse = response.data.result;
          this.activeCustomer = shortResponse.data;
          this.checkProfile();
          console.log("asfasfasfasfasfa");
          if (this.activeCustomer.id) {
            axios.get(this.getApiUrl + 'plugins/dfb/answers?requestId=' + this.$store.state.currentApplication.id, {headers})
              .then(response => {
                this.$log.debug("answeers: ", response);
                let shortResponse = response.data.result;
                this.$log.debug("short", shortResponse);
                this.activeAnswers = shortResponse.data
                this.nachweise = [];
                let i;
                let cA;
                let j;
                let cB;
                console.log("answerCheck", this.activeAnswers);
                for (i in this.activeAnswers) {
                  cA = this.activeAnswers[i];
                  if (i !== 'weiterbildung') {
                    for (j in cA.items) {
                      cB = cA.items[j];
                      if (cB.nachweis_erforderlich === "yes") {
                        if (cB.nachweis === null || cB.nachweis === undefined || !cB.nachweis || cB.nachweis === '' || (typeof cB.nachweis === 'object' && cB.nachweis.length === 0)) {
                          this.nachweise.push(cB);
                        }
                      }
                    }
                  } else {
                    for (j in cA.infos) {
                      let k;
                      for (k in cA.infos[j].items) {
                        cB = cA.infos[j].items[k];
                        if (cB.nachweis_erforderlich === "yes") {
                          console.log("heie", typeof cB.nachweis);
                          if (cB.nachweis === null || cB.nachweis === undefined || !cB.nachweis || cB.nachweis === '' || (typeof cB.nachweis === 'object' && cB.nachweis.length === 0)) {
                            this.nachweise.push(cB);
                          }
                        }
                      }
                    }
                  }
                }
              })
              .catch(error => {
                this.$log.debug(error)
              })
          }
        })
        .catch(error => {
          this.$log.debug(error)
        })
    },
    getOptions() {
      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      axios.get(this.$store.state.apiUrl + 'plugins/dfb/config?requestId=' + this.$store.state.currentApplication.id, {headers})
        .then(response => {
          this.options = response.data.result.data
        })
        .catch(error => {
          this.$log.debug(error)
        })
    },
    checkProfile() {
      let c = this.activeCustomer;
      if (!c) {
        this.profileComplete = false;
      }
      if (!c.c_firstname || !c.c_lastname || !c.c_street || !c.c_birthday || !c.c_zipcode || !c.c_city || !c.c_handy || !c.c_email || !c.birthday_city || !c.custom_fields || !c.custom_fields.liga || !c.custom_fields.altersbereich || !c.custom_fields.mannschaft || !c.profile_image) {
        this.profileComplete = false;
      } else {
        this.profileComplete = true;
      }
      if ((this.tasks.open && this.tasks.open.length) || (this.nachweise && this.nachweise.length > 0) || !this.profileComplete) {
        this.allDone = false;
      } else {
        this.allDone = true;
        this.openPopupGender();
      }
    }
  },
  created() {
    this.$log.debug("creating overview....");
    this.getOptions();
    this.getCustomer();
    this.getTasks();
  }
}
</script>
